.Toolbar {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;

  label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
  }

  select {
    background: none;
    padding: 5px;
    border: none;
    border-bottom: 0.5px solid rgb(146, 146, 146);
  }

  select[name="category"] {
    min-width: 300px;
  }

  select[name="currency"] {
    min-width: 100px;
  }
}
