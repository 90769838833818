.Layout {
  position: relative;
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  main {
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 60px;
    padding: 10px;
    overflow-x: auto;
  }

  footer {
    padding: 10px;
    margin-top: auto;
  }

  h1 {
    margin-bottom: 40px;
    text-align: center;
  }
}
