@import "colors.module";

.Table {
  border-collapse: collapse;
  margin-top: 10px;
  table-layout: fixed;

  thead {
    tr {
      height: 45px;
    }
  }

  tbody {
    tr {
      height: 60px;
    }
  }

  tr {
    border-bottom: 0.5px solid rgb(146, 146, 146);
  }

  th,
  td {
    padding: 0 5px;
    text-align: left;
  }

  th,
  td {
    &.coin {
      width: 400px;

      div {
        display: flex;
        align-items: center;
        gap: 5px;

        img,
        .Skeleton--image {
          margin-right: 10px;
        }
      }
    }

    &.price {
      width: 200px;
    }

    &.change1h,
    &.change24h,
    &.change7d {
      width: 100px;
    }

    &.last7days {
      width: 200px;
    }

    &.red {
      color: $red;
    }

    &.green {
      color: $green;
    }
  }
}
